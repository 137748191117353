/*

 Custom colors for Auri organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #042e6f;
$organization_primary_focus: #00a4e5;
$organization_primary_disabled: #042e6f;

$organization_program_default: #042e6f;

$organization_secondary: #BEDAF7;
$organization_secondary_focus: #BEDAF7;
$organization_secondary_transparent: #00a4e5;
$organization_secundary_border: #ffffff;

$organization_secundary_link: #ffffff;
$organization_secundary_link_hover: #042e6f;

$organization_secundary_button: #00e6ff;
$organization_secundary_button_focus: #042e6f;
